<template>
    <div class="container-fluid">
        <Alert :message="success" type="alert alert-success" v-if="success" />
        <Alert :message="error" type="alert alert-danger" v-if="error" />
        <div class="row">
            <div class="col">
                <div class="card border-left-danger">
                    <div class="card-body">
                    <div class="row">
                        <div class="col">
                        <p 
                        style="float:left; color:black; font-weight:bold;font-size:1.3em" 
                        class="my-2 text-center">Approved entities so that they can be assigned final afcfta numbers</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                        <div class="card">
                            <div class="card-body">
                            <div class="table-responsive">
                                <table
                                class="table table-bordered"
                                id="dataTable"
                                width="100%"
                                cellspacing="0"
                                >
                                <thead>
                                    <tr>
                                    <th>Entity Name</th>
                                    <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(entity, key) in unApprovedEntities" :key="key" >
                                    <td>{{entity.name}}</td>
                                    <td class="actions">
                                        <button class="btn btn-danger"
                                            data-toggle="modal" data-target="#allowDataProvider"
                                            @click="approveEntity(entity.id,key)">
                                            <i class="fas fa-thumbs-up"></i>Approve
                                        </button>
                                    </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                    <th>Entity Name</th>
                                    <th>Actions</th>
                                    </tr>
                                </tfoot>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alert from '@/components/Alert'
import { mapGetters } from 'vuex'

export default {
 components: {
     Alert:Alert
 },
 data() {
         return {
             id:null,
             key:null,
             success:null,
             error:null
         }
     },
 methods:{
    approveEntity(id,key) {
        this.error = null
        this.success = null
        
        this.$store.dispatch('approveEntity',{id:id, key:key})
            .then(response => {
                if(response.status === 200) {
                    this.success = 'AFCFTA number added.'
                }
            }).catch(err => {
                console.log('esrro', err)
                if(err?.response?.data === undefined) {
                this.error = 'Network Error.'
            }
            })
    },
    fetchAllUnapprovedEntities(){
        this.$store.dispatch('fetchAllUnapprovedEntities')
        .catch(err => {
            if(err?.response?.data === undefined) {
            this.error = 'Network Error.'
            }
        })
    }
 },
 computed:{
    ...mapGetters(['unApprovedEntities'])
 },
 created(){
    this.fetchAllUnapprovedEntities()
 },
}
</script>

<style>

</style>